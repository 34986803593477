import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ReactGA from 'react-ga4';
import { AuthContextProvider } from './AuthContext/AuthContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
const App = lazy(() => import(/* webpackChunkName: "app" */ './App'));
const Getinvolved = lazy(() => import(/* webpackChunkName: "getinvolved" */ './components/GetInvolved/Getinvolved'));
const Updates = lazy(() => import(/* webpackChunkName: "updates" */ './components/Updates/Updates'));
const Chiefinvestigators = lazy(() => import(/* webpackChunkName: "chiefinvestigators" */ './components/ChiefInvestigators/ChiefInvestigator'));
const Login = lazy(() => import(/* webpackChunkName: "login" */ './components/Login/LoginForm'));
const Publications = lazy(() => import(/* webpackChunkName: "publications" */ './components/Publications/Publications'));
const MemberAccess = lazy(() => import(/* webpackChunkName: "memberaccess" */ './components/MemberSection/MemberSection'));
const MemberFAQ = lazy(() => import(/* webpackChunkName: "memberaccess" */ './components/MemberAccordian/MemberFAQ'));
const SignUp = lazy(() => import(/* webpackChunkName: "signup" */ './components/SignUp/Signup'));
const PasswordReset = lazy(() => import(/* webpackChunkName: "passwordreset" */ './components/PasswordReset/PasswordReset'));
ReactGA.initialize('G-TNL0HZPW9P');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <Suspense fallback={<Box display='flex' alignItems='center' justifyContent='center' height='100vh'><CircularProgress size='5rem' /></Box>}>
        <AuthContextProvider>
          <Routes>

            <Route path="/memberaccess" element={
              <PrivateRoute>
                <MemberAccess />
              </PrivateRoute>
            } />
            <Route path="/things_to_know" element={
              <PrivateRoute>
                <MemberFAQ />
              </PrivateRoute>
            } />

            <Route path="/getinvolved" element={<Getinvolved />} />
            <Route path="/chiefinvestigators" element={<Chiefinvestigators />} />
            <Route path="/updates" element={<Updates />} />
            <Route path="/login" element={<Login />} />
            <Route path="/passwordreset" element={<PasswordReset />} />
            <Route path="/signup" element={<SignUp />} />
            {/* <Route path="/memberaccess" element={<PrivateRoute />}>
            <Route path="" element={<MemberAccess />} />
          </Route> */}
            {/* <Route path="/memberaccess" element={<MemberAccess />} /> */}
            <Route path="/publications" element={<Publications />} />
            <Route path="/" element={<App />} />
          </Routes>
        </AuthContextProvider>
      </Suspense>
    </React.StrictMode>
  </Router>
);
