// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// import * as firestore from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDlXoHSzaTOevNeNj8s24_uw8IumW6DxxQ",
  authDomain: "sunrise-study.firebaseapp.com",
  projectId: "sunrise-study",
  storageBucket: "sunrise-study.appspot.com",
  messagingSenderId: "441513638267",
  appId: "1:441513638267:web:aac7018e9ac5a63e3465de",
  measurementId: "G-E2CN49YTRV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);  
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lfyz9wmAAAAAO7CfTEDT2yNLf_6wBf6vh1Mz0zj'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const analytics = getAnalytics(app);
export const auth = getAuth(app);
// export const db = firestore();

export default appCheck